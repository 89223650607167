<template>
  <div class="template-set">
    <div class="top-handles">
      <a-button type="primary" size="small" class="mr-16" @click="addGroup"><i class="e-icon add"></i>新增分组</a-button>
      <a-button size="small" @click="onRefresh"><i class="e-icon refresh"></i>刷新</a-button>
    </div>
    <!-- 分组 -->
    <div>
      <div class="group-wrap" v-for="(group, groupIndex) in groups" :key="group.id">
        <div class="group-title-box">
          <div class="tit"><i class="e-icon group"></i>{{group.groupName}}</div>
          <div>
            <a-button type="primary" size="small" class="mr-16" @click="addModule(group, groupIndex)">
              <i class="e-icon add"></i>新增模板
            </a-button>
            <template v-if="!group.isDefault">
              <a-button size="small" class="mr-16" @click="editGroup(group, groupIndex)"><i class="e-icon edit"></i>编辑</a-button>
              <a-button size="small" class="mr-16" @click="removeGroup(group, groupIndex)"><i class="e-icon delete"></i>删除</a-button>
            </template>
            <span class="btn-expand" v-if="!group.isFolded" @click="group.isFolded = true">
              <em class="text">收起</em><a-icon type="up" />
            </span>
            <span class="btn-expand" v-else @click="expandGroupHandle(group, groupIndex)">
              <em class="text">展开</em><a-icon type="down" />
            </span>
          </div>
        </div>
        <div class="group-content" v-if="!group.isFolded">
          <a-empty :image="simpleImage" description="暂无数据" v-if="group.modules.length == 0" />
          <template v-else>
            <div class="module-wrap" v-for="(mod, modIndex) in group.modules" :key="mod.id">
              <div class="module-title-box">
                <div>
                  
                  <span class="tit">{{mod.moduleName}} </span>
                  <span class="tag act" v-if="mod.isEnabled">
                    已启用
                  </span>
                  <span class="tag" v-else>
                    已禁用
                  </span>
                </div>
                <div>
                  
                  <a-button type="primary" size="small" class="mr-16" @click="addProp(group, groupIndex, mod, modIndex)"><i class="e-icon add"></i>新增属性</a-button>
                  <a-button size="small" class="mr-16" @click="openSortProp(group, groupIndex, mod, modIndex)"><a-icon style="transform: rotate(90deg)" type="swap" />属性排序</a-button>
                  <template v-if="!mod.isDefault">
                    <a-button size="small" class="mr-16" @click="editModule(group, groupIndex, mod, modIndex)"><i class="e-icon edit"></i>编辑</a-button>
                    <a-button size="small" class="mr-16" @click="removeModule(group, groupIndex, mod, modIndex)"><i class="e-icon delete"></i>删除</a-button>
                  </template>
                  <span class="btn-expand" v-if="!mod.isFolded" @click="mod.isFolded = true">
                    <em class="text">收起</em><a-icon type="up" />
                  </span>
                  <span class="btn-expand" v-else @click="expandModuleHandle(group, groupIndex, mod, modIndex)">
                    <em class="text">展开</em><a-icon type="down" />
                  </span>
                </div>
              </div>
              <div class="module-content" v-if="!mod.isFolded">
                <a-empty :image="simpleImage" description="暂无数据" v-if="mod.propsData.length == 0" />
                <template v-else>
                  <a-table :columns="columns" :data-source="mod.propsData" rowKey="id" :pagination="false">
                    <template slot="handles" slot-scope="text, propRecord, propIndex">
                      <span class="prop-btn" @click="editProp(group, groupIndex, mod, modIndex, propRecord, propIndex)">编辑</span>
                      <span class="prop-btn" @click="removeProp(group, groupIndex, mod, modIndex, propRecord, propIndex)">删除</span>
                    </template>
                  </a-table>
                </template>
              </div>
            </div>
          </template>
          
        </div>
      </div>
    </div>
    

    <a-modal v-model="addGroupvisible" title="新增分组" centered @ok="addGroupOk">
      <a-form-model
        ref="addGroupForm"
        :model="addGroupForm"
        :rules="addGroupRules"
        :label-col="{span: 5}"
        :wrapper-col="{span: 18}"
      >
        <a-form-model-item label="分组名称" prop="groupName">
          <a-input v-model="addGroupForm.groupName" placeholder="请输入分组名称" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal v-model="editGroupvisible" title="编辑分组" centered @ok="editGroupOk">
      <a-form-model
        ref="editGroupForm"
        :model="editGroupForm"
        :rules="editGroupRules"
        :label-col="{span: 5}"
        :wrapper-col="{span: 18}"
      >
        <a-form-model-item label="分组名称" prop="groupName">
          <a-input v-model="editGroupForm.groupName" placeholder="请输入分组名称" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal v-model="addModulevisible" title="新增模板" centered @ok="addModuleOk">
      <a-form-model
        ref="addModuleForm"
        :model="addModuleForm"
        :rules="addModuleRules"
        :label-col="{span: 5}"
        :wrapper-col="{span: 18}"
      >
        <a-form-model-item label="模板名称" prop="moduleName">
          <a-input v-model="addModuleForm.moduleName" placeholder="请输入模板名称" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal v-model="editModulevisible" title="编辑模板" centered @ok="editModuleOk">
      <a-form-model
        ref="editModuleForm"
        :model="editModuleForm"
        :rules="editModuleRules"
        :label-col="{span: 5}"
        :wrapper-col="{span: 18}"
      >
        <a-form-model-item label="模板名称" prop="moduleName">
          <a-input v-model="editModuleForm.moduleName" placeholder="请输入模板名称" />
        </a-form-model-item>
        <a-form-model-item label="模板状态" required>
          <a-radio-group v-model="editModuleForm.moduleStatus">
            <a-radio :value="1">
              启用
            </a-radio>
            <a-radio :value="0">
              禁用
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="模板分组" required>
          <a-radio-group v-model="editModuleForm.groupIndex">
            <a-radio :value="index" v-for="(group,index) in groups" :key="group.id"  style="display: block; height: 30px; line-height: 30px;">
              {{group.groupName}}
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal v-model="addPropVisible" title="新增属性" centered @ok="addPropOk" :width="700">
      <a-form-model
        ref="addPropForm"
        :model="addPropForm"
        :rules="addPropRules"
        :label-col="{span: 6}"
        :wrapper-col="{span: 18}"
      >
        <a-form-model-item label="属性名称" prop="propName">
          <a-input style="width: 300px" v-model="addPropForm.propName" placeholder="请输入属性名称"/>
        </a-form-model-item>
        <a-form-model-item label="计量单位">
          <a-input style="width: 300px" v-model="addPropForm.unit" placeholder="请输入计量单位（可选）"/>
        </a-form-model-item>

        <a-form-model-item label="控件类型" required>
          <a-select style="width: 300px" v-model="addPropForm.controlType" placeholder="请选择控件类型">
            <a-select-option :value="1">
              输入框
            </a-select-option>
            <a-select-option :value="2">
              下拉框
            </a-select-option>
            <a-select-option :value="3">
              单选框
            </a-select-option>
            <a-select-option :value="4">
              复选框
            </a-select-option>
            <a-select-option :value="5">
              标签组件
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="数据范围" v-if="addPropForm.controlType == 1">
          <a-input style="width: 300px" v-model="addPropForm.presetValue" placeholder="请输入一个默认值（可选）" />
        </a-form-model-item>

        <a-form-model-item label="数据来源" v-if="addPropForm.controlType != 1" required>
          <a-select style="width: 300px" v-model="addPropForm.resourceType" placeholder="请选择数据来源">
            <a-select-option :value="1">
              手动输入
            </a-select-option>
            <a-select-option :value="2">
              选择节点
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="数据范围" v-if="addPropForm.controlType != 1 && addPropForm.resourceType == 1">
          <template v-for="(tag) in addPropForm.range">
            <a-tooltip v-if="tag.length > 10" :key="tag" :title="tag">
              <a-tag :key="tag" :closable="true" @close="() => handleClose(tag)">
                {{ `${tag.slice(0, 10)}...` }}
              </a-tag>
            </a-tooltip>
            <a-tag v-else :key="tag" :closable="true" @close="() => handleClose(tag)">
              {{ tag }}
            </a-tag>
          </template>
          <a-input
            v-if="addPropForm.inputVisible"
            ref="input"
            type="text"
            size="small"
            :style="{ width: '78px' }"
            :value="addPropForm.inputValue"
            @change="handleInputChange"
            @blur="handleInputConfirm"
            @keyup.enter="handleInputConfirm"
          />
          <a-tag v-else style="background: #fff; borderStyle: dashed;" @click="showInput">
            <a-icon type="plus" />新数据
          </a-tag>
        </a-form-model-item>

        <a-form-model-item label="数据范围" v-if="addPropForm.controlType != 1 && addPropForm.resourceType == 2">
          <a-cascader
            style="width: 220px"
						:fieldNames="{
							label: 'tagName',
							value: 'tagId',
							children: 'children'
						}"
						:options="treeData"
						change-on-select
						placeholder="请选择父节点"
            v-model="addPropForm.path"
            @change="addTagSelectPath"
					/>
          <a-button @click="addTagSelectPathConfirm" style="width:76px; margin-left: 4px;">确定</a-button>
          <br>
          <template v-if="addPropForm.pathRange.length > 0">
            <a-checkbox-group v-model="addPropForm.checkRange">
              <a-checkbox :value="item" name="dataRange" 
                v-for="item in addPropForm.pathRange" :key="item"
                style="margin-left: 0; margin-right: 8px;"
              >
                {{item}}
              </a-checkbox>
            </a-checkbox-group>
          </template>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!-- 编辑属性 -->
    <a-modal v-model="editPropVisible" title="编辑属性" centered @ok="editPropOk" :width="700">
      <a-form-model
        ref="editPropForm"
        :model="editPropForm"
        :rules="editPropRules"
        :label-col="{span: 6}"
        :wrapper-col="{span: 18}"
      >
        <a-form-model-item label="属性名称" prop="propName">
          <a-input style="width: 300px" v-model="editPropForm.propName" placeholder="请输入属性名称"/>
        </a-form-model-item>
        <a-form-model-item label="计量单位">
          <a-input style="width: 300px" v-model="editPropForm.unit" placeholder="请输入计量单位（可选）"/>
        </a-form-model-item>

        <a-form-model-item label="控件类型" required>
          <a-select style="width: 300px" v-model="editPropForm.controlType" placeholder="请选择控件类型">
            <a-select-option :value="1">
              输入框
            </a-select-option>
            <a-select-option :value="2">
              下拉框
            </a-select-option>
            <a-select-option :value="3">
              单选框
            </a-select-option>
            <a-select-option :value="4">
              复选框
            </a-select-option>
            <a-select-option :value="5">
              标签组件
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="数据范围" v-if="editPropForm.controlType == 1">
          <a-input style="width: 300px" v-model="editPropForm.presetValue" placeholder="请输入一个默认值（可选）" />
        </a-form-model-item>

        <a-form-model-item label="数据来源" v-if="editPropForm.controlType != 1" required>
          <a-select style="width: 300px" v-model="editPropForm.resourceType" placeholder="请选择数据来源">
            <a-select-option :value="1">
              手动输入
            </a-select-option>
            <a-select-option :value="2">
              选择节点
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="数据范围" v-if="editPropForm.controlType != 1 && editPropForm.resourceType == 1">
          <template v-for="(tag) in editPropForm.range">
            <a-tooltip v-if="tag.length > 10" :key="tag" :title="tag">
              <a-tag :key="tag" :closable="true" @close="() => editHandleClose(tag)">
                {{ `${tag.slice(0, 10)}...` }}
              </a-tag>
            </a-tooltip>
            <a-tag v-else :key="tag" :closable="true" @close="() => editHandleClose(tag)">
              {{ tag }}
            </a-tag>
          </template>
          <a-input
            v-if="editPropForm.inputVisible"
            ref="input"
            type="text"
            size="small"
            :style="{ width: '78px' }"
            :value="editPropForm.inputValue"
            @change="editHandleInputChange"
            @blur="editHandleInputConfirm"
            @keyup.enter="editHandleInputConfirm"
          />
          <a-tag v-else style="background: #fff; borderStyle: dashed;" @click="editShowInput">
            <a-icon type="plus" />新数据
          </a-tag>
        </a-form-model-item>

        <a-form-model-item label="数据范围" v-if="editPropForm.controlType != 1 && editPropForm.resourceType == 2">
          <a-cascader
            style="width: 220px"
						:fieldNames="{
							label: 'tagName',
							value: 'tagId',
							children: 'children'
						}"
						:options="treeData"
						change-on-select
						placeholder="请选择父节点"
            v-model="editPropForm.path"
            @change="editTagSelectPath"
					/>
          <a-button @click="editTagSelectPathConfirm" style="width:76px; margin-left: 4px;">确定</a-button>
          <br>
          <template v-if="editPropForm.pathRange.length > 0">
            <a-checkbox-group v-model="editPropForm.checkRange">
              <a-checkbox :value="item" name="dataRange" 
                v-for="item in editPropForm.pathRange" :key="item"
                style="margin-left: 0; margin-right: 8px;"
              >
                {{item}}
              </a-checkbox>
            </a-checkbox-group>
          </template>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!-- 属性排序 -->
    <a-modal v-model="sortPropVisible" title="属性排序" centered @ok="sortPropOk">
      <p style="color: #999">您可以直接通过拖拽调节顺序</p>
      <draggable
        class="drag-list-group"
        tag="div"
        v-model="newSortList"
        v-bind="dragOptions"
        @start="isDragging = true"
        @end="isDragging = false"
      >
        <transition-group type="transition" name="flip-list">
          <div
            class="list-group-item"
            v-for="element in newSortList"
            :key="element.id"
          >
            <!-- <i
              :class="
                element.fixed ? 'fa fa-anchor' : 'glyphicon glyphicon-pushpin'
              "
              @click="element.fixed = !element.fixed"
              aria-hidden="true"
            ></i> -->
            {{ element.name }}
          </div>
        </transition-group>
      </draggable>
      <a-button type="link" style="margin-top: 8px; padding: 0" @click="sortRest">重置顺序</a-button>
    </a-modal>

  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import draggable from 'vuedraggable'
import { mapGetters } from 'vuex'
import {
  groupList,
  propertyList,
  propertySort,
  removeGroup,
  removeProperty,
  removeTemplate,
  saveGroup,
  saveProperty,
  saveTemplate,
  templateList,
  updateGroup,
  updateProperty,
  updateTemplate,
} from '@/api/system'
import { ControlTypes } from '@/utils/dic-data.js'
import { listTag, listTree } from '@/api/tag'
const columns = [
  {
    align: 'center',
    title: '属性名称',
    dataIndex: 'propName'
  },
  {
    align: 'center',
    title: '控件类型',
    dataIndex: 'propType'
  },
  {
    align: 'center',
    title: '数据来源',
    dataIndex: 'dataSource',
  },
  {
    align: 'center',
    title: '数据范围/默认值',
    dataIndex: 'dataRange'
  }, 
  {
    align: 'center',
    title: '相关操作',
    width: 160,
    scopedSlots: { customRender: 'handles'}
  },
]
export default {
  name: 'static-data-goods-template-index',
  components: {
    draggable
  },
  data() {
    return {
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      columns,

      groups: [
        // {
        //   id: 1,
        //   isDefault: true,
        //   groupName: '默认分组',
        //   isFolded: false,
        //   modules: [
        //     {
        //       id: 1,
        //       moduleName: '面辅料供应',
        //       isDefault: true,
        //       isFolded: false,
        //       isEnabled: true,
        //       propsData: [
        //         {
        //           id: 1,
        //           propName: '生产产能',
        //           propType: 1,
        //           dataSource: 1, // 1 - 手动输入  2 - 标签数据
        //           dataRange: []
        //         },
        //         {
        //           id: 2,
        //           propName: '生产类型',
        //           propType: 2,
        //           dataSource: 2, // 1 - 手动输入  2 - 标签数据
        //           dataRange: []
        //         }
        //       ]
        //     },
        //     {
        //       id: 2,
        //       moduleName: '产品设计',
        //       isDefault: true,
        //       isFolded: false,
        //       isEnabled: true,
        //       propsData: []
        //     },
        //     {
        //       id: 3,
        //       moduleName: '生产加工',
        //       isDefault: true,
        //       isFolded: false,
        //       isEnabled: true,
        //       propsData: []
        //     },
        //     {
        //       id: 4,
        //       moduleName: '销售',
        //       isDefault: true,
        //       isFolded: false,
        //       isEnabled: true,
        //       propsData: []
        //     },
        //     {
        //       id: 5,
        //       moduleName: '软件服务',
        //       isDefault: true,
        //       isFolded: false,
        //       isEnabled: true,
        //       propsData: []
        //     },
        //     {
        //       id: 6,
        //       moduleName: '硬件服务',
        //       isDefault: true,
        //       isFolded: false,
        //       isEnabled: true,
        //       propsData: []
        //     }
        //   ]
        // }
      ],
      defaultGroupIndex: 0,

      // 新增分组
      addGroupvisible: false,
      addGroupForm: {
        groupName: ''
      },
      addGroupRules: {
        groupName: [
          {required: true, message: '请输入分组名称', trigger: 'blur'},
          { min: 1, max: 10, message: '名称长度最多10个字符', trigger: 'blur' },
        ]
      },

      // 编辑分组
      editGroupvisible: false,
      editGroupForm: {
        groupName: ''
      },
      editGroupRules: {
        groupName: [
          {required: true, message: '请输入分组名称', trigger: 'blur'},
          { min: 1, max: 10, message: '名称长度最多10个字符', trigger: 'blur' },
        ]
      },

      currentHandleGroup: null,   // 当前操作的分组
      currentHandleGroupIndex: 0, // 当前操作的分组序号
      currentHandleMod: null,     // 当前操作的业务模板
      currentHandleModIndex: 0,   // 当前操作的业务模板序号
      currentHandleProp: null,    // 当前操作的属性
      currentHandlePropIndex: 0,  // 当前操作的属性序号


      // 新增模板
      addModulevisible: false,
      addModuleForm: {
        moduleName: ''
      },
      addModuleRules: {
        moduleName: [
          {required: true, message: '请输入分组名称', trigger: 'blur'},
          { min: 1, max: 10, message: '名称长度最多10个字符', trigger: 'blur' },
        ]
      },
      // 编辑模板
      editModulevisible: false,
      editModuleForm: {
        moduleName: '',
        moduleStatus: '',
        groupIndex: '',
      },
      editModuleRules: {
        moduleName: [
          {required: true, message: '请输入分组名称', trigger: 'blur'},
          { min: 1, max: 10, message: '名称长度最多10个字符', trigger: 'blur' },
        ]
      },

      // 新增属性
      treeData: [
        // {
        //   tagId: '企业',
        //   tagName: '企业',
        //   children: [
        //     {
        //       tagId: '企业1',
        //       tagName: '企业1',
        //       children: [
        //         {
        //           tagId: '企业1-1',
        //           tagName: '企业1-1',
        //         },
        //         {
        //           tagId: '企业1-2',
        //           tagName: '企业1-2',
        //         },
        //         {
        //           tagId: '企业1-3',
        //           tagName: '企业1-3',
        //         },
        //         {
        //           tagId: '企业1-4',
        //           tagName: '企业1-4',
        //         },
        //       ],
        //     },
        //     {
        //       tagId: '企业2',
        //       tagName: '企业2',
        //       children: [
        //         {
        //           tagId: '企业2-1',
        //           tagName: '企业2-1',
        //         },
        //         {
        //           tagId: '企业2-2',
        //           tagName: '企业2-2',
        //         },
        //         {
        //           tagId: '企业2-3',
        //           tagName: '企业2-3',
        //         },
        //         {
        //           tagId: '企业2-4',
        //           tagName: '企业2-4',
        //         },
        //       ],
        //     },
        //   ],
        // },
        // {
        //   tagId: '商品',
        //   tagName: '商品',
        //   children: [
        //     {
        //       tagId: '商品1',
        //       tagName: '商品1',
        //       children: [
        //         {
        //           tagId: '商品1-1',
        //           tagName: '商品1-1',
        //         },
        //         {
        //           tagId: '商品1-2',
        //           tagName: '商品1-2',
        //         },
        //         {
        //           tagId: '商品1-3',
        //           tagName: '商品1-3',
        //         },
        //         {
        //           tagId: '商品1-4',
        //           tagName: '商品1-4',
        //         },
        //       ],
        //     },
        //     {
        //       tagId: '商品2',
        //       tagName: '商品2',
        //       children: [
        //         {
        //           tagId: '商品2-1',
        //           tagName: '商品2-1',
        //         },
        //         {
        //           tagId: '商品2-2',
        //           tagName: '商品2-2',
        //         },
        //         {
        //           tagId: '商品2-3',
        //           tagName: '商品2-3',
        //         },
        //         {
        //           tagId: '商品2-4',
        //           tagName: '商品2-4',
        //         },
        //       ],
        //     },
        //   ],
        // },
      ],
      addPropVisible: false,
      addPropForm: {
        inputVisible: false,
        inputValue: '',
        tempPathRange: [],
        pathRange: [],

        propName: '',
        unit: '',
        controlType: 1,
        resourceType: 1,
        path: [],
        checkRange: [],
        range: [],
        presetValue: '',
      },
      addPropRules: {
        propName: [
          {required: true, message: '请输入属性名称', trigger: 'blur'},
        ]
      },

      editPropVisible: false,
      
      editPropForm: {
        inputVisible: false,
        inputValue: '',
        tempPathRange: [],
        pathRange: [],

        propName: '',
        unit: '',
        controlType: 1,
        resourceType: 1,
        path: [],
        checkRange: [],
        range: [],
        presetValue: '',
      },
      editPropRules: {
        propName: [
          {required: true, message: '请输入属性名称', trigger: 'blur'},
        ]
      },


      sortPropVisible: false,
      dragOptions:{
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      },
      newSortList: []
      
    }
  },
  computed: {
    ...mapGetters(['adminId'])
  },
  created() {
    this.groupList()
    this.listTag()
  },
  methods: {
    // 刷新操作
    onRefresh() {
      this.groups = []
      this.groupList()
    },

    // 新增分组
    addGroup() {
      this.addGroupvisible = true
    },
    addGroupOk() {
      this.$refs['addGroupForm'].validate(valid => {
        if (valid) {
          console.log('pass')
          // this.addGroupvisible = false
          // this.groups.push({
          //   id: '' + Math.random(),
          //   isDefault: false,
          //   groupName: this.addGroupForm.groupName,
          //   isFolded: false,
          //   modules: []
          // })
          // this.addGroupForm.groupName = ''
          // this.$message.success('新增成功')
          this.$showLoading()
          saveGroup({
            adminId: this.adminId,
            groupName: this.addGroupForm.groupName.trim(),
            type: 2
          }).then(res => {
            this.$hideLoading()
            console.log(res)
            if (res.code == 200) {
              this.addGroupvisible = false
              this.addGroupForm.groupName = ''
              this.$message.success('新增成功')
              this.groupList()
            } else {
              this.$message.error(res.msg || '网络错误')
            }
          }).catch(err => {
            this.$hideLoading()
            console.warn(err)
          })
        } else {
          console.log('no-pass')
        }
      })
    },

    // 编辑分组
    editGroup(group, groupIndex) {
      this.currentHandleGroup = group
      this.currentHandleGroupIndex = groupIndex
      this.editGroupvisible = true
      this.editGroupForm.groupName = this.currentHandleGroup.groupName
    },
    editGroupOk() {
      this.$refs['editGroupForm'].validate(valid => {
        if (valid) {
          console.log('pass')
          this.$showLoading()
          updateGroup({
            adminId: this.adminId,
            id: this.groups[this.currentHandleGroupIndex].id,
            groupName: this.editGroupForm.groupName,
            type: 2
          }).then(res => {
            console.log(res)
            if (res.code == 200) {
              this.editGroupvisible = false
              this.groups[this.currentHandleGroupIndex].groupName = this.editGroupForm.groupName
              this.editGroupForm.groupName = ''
              this.$message.success('编辑成功')
            } else {
              this.$message.error(res.msg || '网络错误')
            }
          }).catch(err => {
            console.warn(err)
          }).finally(() => {
            this.$hideLoading()
          })
        } else {
          console.log('no-pass')
        }
      })
    },
    // 删除分组
    removeGroup(group, groupIndex) {
      this.currentHandleGroup = group
      this.currentHandleGroupIndex = groupIndex
      this.$confirm({
        title: '确定删除该分组？',
        content: '注：删除该分组后，当前分组模板将移动于默认分组中',
        centered: true,
        onOk: () => {
          // let modules = this.currentHandleGroup.modules
          // this.groups.splice(groupIndex, 1)
          // this.groups[0].modules = this.groups[0].modules.concat(modules)
          // this.$message.success('删除成功')
          this.$showLoading()
          removeGroup({
            adminId: this.adminId,
            id: this.groups[this.currentHandleGroupIndex].id,
          }).then(res => {
            console.log(res)
            if (res.code == 200) {
              let modules = this.currentHandleGroup.modules
              this.groups.splice(groupIndex, 1)
              this.groups[this.defaultGroupIndex].modules = this.groups[this.defaultGroupIndex].modules.concat(modules)
              this.$message.success('删除成功')
            } else {
              this.$message.error(res.msg || '网络错误')
            }
          }).catch(err => {
            console.warn(err)
          }).finally(() => {
            this.$hideLoading()
          })
        },
        onCancel: () => {
          console.log('Cancel');
        },
      })
    },
    // 展开模板
    expandGroupHandle(group, groupIndex) {
      this.currentHandleGroup = group
      this.currentHandleGroupIndex = groupIndex
      group.isFolded = false
      if (group.modules.length == 0) {
        this.templateList(group.id)
      }
    },

    // 新增模板
    addModule(group, groupIndex) {
      this.addModulevisible = true
      this.currentHandleGroup = group
      this.currentHandleGroupIndex = groupIndex
    },
    // 确认新增模板
    addModuleOk() {
      this.$refs['addModuleForm'].validate(valid => {
        if (valid) {
          console.log('pass')
          // this.addModulevisible = false
          // this.groups[this.currentHandleGroupIndex].modules.push({
          //   id: '' + Math.random(),
          //   moduleName: this.addModuleForm.moduleName,
          //   isDefault: false,
          //   isFolded: false,
          //   isEnabled: true,
          //   propsData: []
          // })
          // this.currentHandleGroup = null
          // this.currentHandleGroupIndex = 0
          // this.addModuleForm.moduleName = ''
          // this.$message.success('新增成功')
          this.$showLoading()
          saveTemplate({
            adminId: this.adminId,
            parentId: this.groups[this.currentHandleGroupIndex].id,
            templateName: this.addModuleForm.moduleName,
            type: 2,
          }).then(res => {
            this.$hideLoading()
            console.log(res)
            if (res.code == 200) {
              this.addModulevisible = false
              this.addModuleForm.moduleName = ''
              this.templateList(this.groups[this.currentHandleGroupIndex].id)
            } else {
              this.$message.error(res.msg || '网络错误')
            }
          }).catch(err => {
            this.$hideLoading()
            console.log(err)
          })
        } else {
          console.log('no-pass')
        }
      })
    },
    // 编辑模板
    editModule(group, groupIndex, mod, modIndex) {
      this.currentHandleGroup = group
      this.currentHandleGroupIndex = groupIndex
      this.currentHandleMod = mod
      this.currentHandleModIndex = modIndex

      this.editModuleForm.moduleName = mod.moduleName
      this.editModuleForm.moduleStatus = mod.isEnabled ? 1 : 0
      this.editModuleForm.groupIndex = groupIndex
      console.log(this.editModuleForm)

      this.editModulevisible = true
      
    },
    // 确认编辑模板
    editModuleOk() {
      this.$refs['editModuleForm'].validate(valid => {
        if (valid) {
          console.log('pass')
          this.editModulevisible = false
          console.log(this.editModuleForm)
          updateTemplate({
            adminId: this.adminId,
            id: this.currentHandleMod.id,
            parentId: this.groups[this.editModuleForm.groupIndex].id,
            status: this.editModuleForm.moduleStatus,
            templateName: this.editModuleForm.moduleName
          }).then(res => {
            if (res.code == 200) {
              this.$message.success('编辑成功')
              if (this.editModuleForm.groupIndex == this.currentHandleGroupIndex) {
                this.groups[this.currentHandleGroupIndex].modules[this.currentHandleModIndex].moduleName = this.editModuleForm.moduleName
                this.groups[this.currentHandleGroupIndex].modules[this.currentHandleModIndex].isEnabled = this.editModuleForm.moduleStatus === 1
              } else {
                let module = {
                  ...this.currentHandleMod,
                  moduleName: this.editModuleForm.moduleName,
                  isEnabled: this.editModuleForm.moduleStatus === 1
                }
                this.groups[this.currentHandleGroupIndex].modules.splice(this.currentHandleModIndex, 1)
                this.groups[this.editModuleForm.groupIndex].modules.push(module)
              }
            } else {
              this.$message.error(res.msg || '网络错误')
            }
          }).catch(err => {
            console.warn(err)
          })
        } else {
          console.log('no-pass')
        }
      })
    },
    // 删除模板
    removeModule(group, groupIndex, mod, modIndex) {
      this.currentHandleGroup = group
      this.currentHandleGroupIndex = groupIndex
      this.currentHandleMod = mod
      this.currentHandleModIndex = modIndex
      this.$confirm({
        title: '确定删除该业务模板?',
        content: '模板属性将一同删除， 请谨慎操作',
        centered: true,
        onOk: () => {
          removeTemplate({
            adminId: this.adminId,
            id: mod.id
          }).then(res => {
            if (res.code == 200) {
              this.groups[this.currentHandleGroupIndex].modules.splice(this.currentHandleModIndex, 1)
              this.$message.success('删除成功')
            } else {
              this.$message.err(res.msg || '网络错误')
            }
          }).catch(err => {
            console.warn(err)
          })
        },
        onCancel: () => {
          console.log('Cancel');
        },
      })
    },
    
    // 展开属性
    expandModuleHandle(group, groupIndex, mod, modIndex) {
      this.currentHandleGroup = group
      this.currentHandleGroupIndex = groupIndex
      this.currentHandleMod = mod
      this.currentHandleModIndex = modIndex
      mod.isFolded = false
      if (mod.propsData.length == 0) {
        this.propertyList(mod.id)
      }
    },
    // 新增属性
    addProp(group, groupIndex, mod, modIndex) {
      console.log(group, groupIndex, mod, modIndex)
      this.currentHandleGroup = group
      this.currentHandleGroupIndex = groupIndex
      this.currentHandleMod = mod
      this.currentHandleModIndex = modIndex
      this.addPropVisible = true
    },

    handleClose(removedTag) {
      const tags = this.addPropForm.range.filter(tag => tag !== removedTag)
      console.log(tags)
      this.addPropForm.range = tags
    },

    showInput() {
      this.addPropForm.inputVisible = true;
      this.$nextTick(function() {
        this.$refs.input.focus();
      });
    },

    handleInputChange(e) {
      this.addPropForm.inputValue = e.target.value;
    },

    handleInputConfirm() {
      const inputValue = this.addPropForm.inputValue
      let tags = this.addPropForm.range
      if (inputValue && tags.indexOf(inputValue) === -1) {
        tags = [...tags, inputValue]
      }
      console.log(tags)
      this.addPropForm.range = tags
      this.addPropForm.inputVisible = false
      this.addPropForm.inputValue = ''
    },

    addTagSelectPath(value, selectedOptions){
      console.log(value, selectedOptions)
      let pathRange = []
      let L = value.length
      if (L > 0 && selectedOptions[L-1] && selectedOptions[L-1].children) {
        pathRange = selectedOptions[L-1].children.map(tag => tag.tagName)
      } else {
        pathRange = []
      }
      this.addPropForm.tempPathRange = pathRange
    },
    addTagSelectPathConfirm() {
      this.addPropForm.pathRange = [...this.addPropForm.tempPathRange]
      this.addPropForm.checkRange = [...this.addPropForm.tempPathRange]
    },

    // 确认新增属性
    addPropOk() {
      console.log(this.addPropForm)
      this.$refs['addPropForm'].validate(valid => {
        if (valid) {
          let propertyContent = {
            controlType: this.addPropForm.controlType, // 控件类型 1 2 3 4 5
            unit: this.addPropForm.unit, // 单位
          }
          if (this.addPropForm.controlType == 1) {
            propertyContent.presetValue = this.addPropForm.presetValue
          } else {
            propertyContent['resourceType'] = this.addPropForm.resourceType  // 1 - 手动输入  2 - 标签数据
            if (this.addPropForm.resourceType == 1) {
              propertyContent.range = this.addPropForm.range
            } else if (this.addPropForm.resourceType == 2) {
              propertyContent.range = this.addPropForm.checkRange
              propertyContent.path = this.addPropForm.path
            } else {
              console.warn('data error')
            }
          }
          console.log(propertyContent)
          this.$showLoading()
          saveProperty({
            adminId: this.adminId,
            parentId: this.currentHandleMod.id,
            propertyName: this.addPropForm.propName,
            propertyContent: JSON.stringify(propertyContent),
            type: 2
          }).then(res => {
            console.log(res)
            this.$hideLoading()
            if (res.code == 200) {
              this.addPropVisible = false
              this.addPropForm = {
                inputVisible: false,
                inputValue: '',
                tempPathRange: [],
                pathRange: [],
                propName: '',
                unit: '',
                controlType: 1,
                resourceType: 1,
                path: [],
                checkRange: [],
                range: [],
                presetValue: '',
              }
              this.propertyList(this.currentHandleMod.id)
            } else {
              this.$message.error(res.msg || '网络错误')
            }
          }).catch(err => {
            this.$hideLoading()
            console.warn(err)
          })
        }
      })
    },

    // 属性排序
    openSortProp(group, groupIndex, mod, modIndex) {
      this.currentHandleGroup = group
      this.currentHandleGroupIndex = groupIndex
      this.currentHandleMod = mod
      this.currentHandleModIndex = modIndex
      if (mod.propsData.length == 0) {
        this.propertyList(mod.id, () => {
          this.newSortList = [...this.currentHandleMod.propsData]
        })
      } else {
        this.newSortList = [...this.currentHandleMod.propsData]
      }
      this.sortPropVisible = true
    },

    sortRest() {
      this.newSortList = this.newSortList.sort((a, b) => a.sequence - b.sequence)
    },
    
    sortPropOk() {
      console.log('this.newSortList', this.newSortList)
      let submitSortList = this.newSortList.map((item, index) => {
        return {
          v1: item.id,
          v2: index + 1
        }
        // return item.id
      })
      console.log(submitSortList)
      this.$showLoading()
      propertySort({
        adminId: this.adminId,
        sortList: submitSortList // JSON.stringify(submitSortList)
      }).then(res => {
        this.$hideLoading()
        console.log(res)
        if (res.code == 200) {
          this.sortPropVisible = false
          this.propertyList(this.currentHandleMod.id)
        } else {
          this.$message.error(res.msg || '网络错误')
        }
      }).catch(err => {
        this.$hideLoading()
        console.warn(err)
      })
    },

    // 编辑属性
    editProp(group, groupIndex, mod, modIndex, propRecord, propIndex) {
      console.log(group, groupIndex, mod, modIndex, propRecord, propIndex)
      this.currentHandleGroup = group
      this.currentHandleGroupIndex = groupIndex
      this.currentHandleMod = mod
      this.currentHandleModIndex = modIndex
      this.currentHandleProp = propRecord
      this.currentHandlePropIndex = propIndex
      this.editPropVisible = true
    
      let propertyContent = {}
      try {
        propertyContent = JSON.parse(propRecord.propertyContent)
      } catch (error) {
        console.warn(error)
      }
      
      this.editPropForm.propName = propRecord.name || ''
      this.editPropForm.unit = propertyContent.unit || ''
      this.editPropForm.controlType = propertyContent.controlType || 1
      this.editPropForm.presetValue = propertyContent.presetValue || ''
      this.editPropForm.resourceType = propertyContent.resourceType || 1
      this.editPropForm.range = [...propertyContent.range]
      this.editPropForm.checkRange = [...propertyContent.range]
      this.editPropForm.pathRange = [...propertyContent.range]

      // this.editPropForm = 
      //   inputVisible: false,
      //   inputValue: '',
      //   tempPathRange: [],
      //   pathRange: [],

      //   propName: '',
      //   unit: '',
      //   controlType: 1,
      //   resourceType: 1,
      //   path: [],
      //   checkRange: [],
      //   range: [],
      //   presetValue: '',
    },

    editHandleClose(removedTag) {
      const tags = this.editPropForm.range.filter(tag => tag !== removedTag)
      console.log(tags)
      this.editPropForm.range = tags
    },

    editShowInput() {
      this.editPropForm.inputVisible = true;
      this.$nextTick(function() {
        this.$refs.input.focus();
      });
    },

    editHandleInputChange(e) {
      this.editPropForm.inputValue = e.target.value;
    },

    editHandleInputConfirm() {
      const inputValue = this.editPropForm.inputValue
      let tags = this.editPropForm.range
      if (inputValue && tags.indexOf(inputValue) === -1) {
        tags = [...tags, inputValue]
      }
      console.log(tags)
      this.editPropForm.range = tags
      this.editPropForm.inputVisible = false
      this.editPropForm.inputValue = ''
    },

    editTagSelectPath(value, selectedOptions){
      console.log(value, selectedOptions)
      let pathRange = []
      let L = value.length
      if (L > 0 && selectedOptions[L-1] && selectedOptions[L-1].children) {
        pathRange = selectedOptions[L-1].children.map(tag => tag.tagName)
      } else {
        pathRange = []
      }
      this.editPropForm.tempPathRange = pathRange
    },
    editTagSelectPathConfirm() {
      this.editPropForm.pathRange = [...this.editPropForm.tempPathRange]
      this.editPropForm.checkRange = [...this.editPropForm.tempPathRange]
    },

    // 确认编辑属性
    editPropOk() {
      console.log(this.editPropForm)
      this.$refs['editPropForm'].validate(valid => {
        if (valid) {
          let propertyContent = {
            controlType: this.editPropForm.controlType, // 控件类型 1 2 3 4 5
            unit: this.editPropForm.unit, // 单位
          }
          if (this.editPropForm.controlType == 1) {
            propertyContent.presetValue = this.editPropForm.presetValue
          } else {
            propertyContent['resourceType'] = this.editPropForm.resourceType  // 1 - 手动输入  2 - 标签数据
            if (this.editPropForm.resourceType == 1) {
              propertyContent.range = this.editPropForm.range
            } else if (this.editPropForm.resourceType == 2) {
              propertyContent.range = this.editPropForm.checkRange
              propertyContent.path = this.editPropForm.path
            } else {
              console.warn('data error')
            }
          }
          console.log(propertyContent)
          this.$showLoading()
          updateProperty({
            adminId: this.adminId,
            id: this.currentHandleProp.id,
            parentId: this.currentHandleMod.id,
            propertyName: this.editPropForm.propName,
            propertyContent: JSON.stringify(propertyContent)
          }).then(res => {
            console.log(res)
            this.$hideLoading()
            if (res.code == 200) {
              this.editPropVisible = false
              this.propertyList(this.currentHandleMod.id)
            } else {
              this.$message.error(res.msg || '网络错误')
            }
          }).catch(err => {
            this.$hideLoading()
            console.warn(err)
          })
        }
      })
    },

    // 删除属性
    removeProp(group, groupIndex, mod, modIndex, propRecord, propIndex) {
      this.currentHandleGroup = group
      this.currentHandleGroupIndex = groupIndex
      this.currentHandleMod = mod
      this.currentHandleModIndex = modIndex
      this.$confirm({
        title: '确定删除该条属性吗?',
        centered: true,
        onOk: () => {
          removeProperty({
            adminId: this.adminId,
            id: propRecord.id
          }).then(res => {
            if (res.code == 200) {
              this.groups[groupIndex].modules[modIndex].propsData.splice(propIndex, 1)
              this.$message.success('删除成功')
            } else {
              this.$message.err(res.msg || '网络错误')
            }
          }).catch(err => {
            console.warn(err)
          })
        },
        onCancel: () => {
          console.log('Cancel');
        },
      })
    },

    // 请求接口
    // 获取分组列表
    groupList() {
      this.$showLoading()
      groupList({type: 2}).then(res => {
        console.log(res)
        if (res.code == 200) {
          let responseData = res.data //.sort((a, b) => a.id - b.id)
          if (this.groups.length == 0) {
            this.groups = responseData.map((item, index) => {
              let isDefault = item.isDefault == 1
              if (isDefault) this.defaultGroupIndex = index
              return {
                ...item,
                isDefault,
                isFolded: true,
                groupName: item.name,
                modules: []
              }
            })
          } else {
            let result = responseData.map((item, index) => {
              let sameIndex = this.groups.findIndex(g => g.id == item.id)
              let isDefault = item.isDefault == 1
              if (isDefault) this.defaultGroupIndex = index
              if (sameIndex >= 0) {
                return this.groups[sameIndex]
              } else {
                return {
                  ...item,
                  isDefault,
                  isFolded: true,
                  groupName: item.name,
                  modules: []
                }
              }
            })
            this.groups = [...result]
          }
        } else {
          this.$message.error(res.msg || '网络错误')
        }
      }).catch(err => {
        console.warn(err)
      }).finally(() => {
        this.$hideLoading()
      })
    },
    // 获取模板列表
    templateList(id) {
      this.$showLoading()
      templateList({parentId: id}).then(res => {
        console.log(res)
        if (res.code == 200) {
          let groupIndex = this.currentHandleGroupIndex
          let curModules = this.groups[groupIndex].modules
          if (curModules.length == 0) {
            this.groups[groupIndex].modules = res.data.map(item => {
              return {
                ...item,
                moduleName: item.name,
                isDefault: item.isDefault == 1,
                isFolded: true,
                isEnabled: item.status == 1,  // status  1 - 启用  0 - 禁用
                propsData: []
              }
            })
          } else {
            let result = res.data.map(item => {
              let sameIndex = curModules.findIndex(g => g.id == item.id)
              if (sameIndex>=0) {
                return curModules[sameIndex]
              } else {
                return {
                  ...item,
                  moduleName: item.name,
                  isDefault: item.isDefault == 1,
                  isFolded: true,
                  isEnabled: item.status == 1,  // status  1 - 启用  0 - 禁用
                  propsData: []
                }
              }
            })
            this.groups[groupIndex].modules = result
          }
        } else {
          this.$message.error(res.msg || '网络错误')
        }
      }).catch(err => {
        console.warn(err)
      }).finally(() => {
        this.$hideLoading()
      })
    },
    propertyList(id, cb) {
      this.$showLoading()
      propertyList({parentId: id}).then(res => {
        console.log(res)
        if (res.code == 200) {
          this.groups[this.currentHandleGroupIndex].modules[this.currentHandleModIndex].propsData = res.data.map(item => {
            let propertys = JSON.parse(item.propertyContent)

            let dataRange = ''
            if (propertys.controlType == 1) {
              dataRange = propertys.presetValue || ''
            } else {
              dataRange = propertys.range.join('、')
            }

            let dataSource = ''
            if (propertys.controlType == 1) {
              dataSource = '-'
            } else {
              dataSource = propertys.resourceType == 1 ? '手动输入' : '企业数据' // 1 - 手动输入  2 - 企业数据
            }

            return {
              ...item,
              propName: item.name,
              propType: ControlTypes[propertys.controlType] || '-',
              dataSource,  
              dataRange,
              propertys
            }
          })
          if (cb) cb()
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.$hideLoading()
      })
    },
    // 标签列表
		listTag() {
			this.$showLoading()
			listTag({
				parentId: 0
			}).then(res => {
				console.log(res)
				if (res.code == 200) {
					if (res.data && Array.isArray(res.data)) {
						let companyTag = res.data.find(item => item.tagName == '企业数据')
            if (companyTag) {
              this.listTree(companyTag.tagId)
            }
					} 
				}
			}).catch(err => {
				console.log(err)
			}).finally(() => {
				this.$hideLoading()
			})
		},

    // 获取标签库
    listTree(id, cb) {
      listTree({tagId: id}).then(res => {
        console.log('listTree', res)
        if (res.code == 200) {
          this.treeData = res.data
          if (cb) cb()
        }
      }).catch(err => {
        console.log('listTree', err)
      })
    },
  }
}
</script>

<style lang="less" scoped>
.template-set {
  .top-handles {
    padding: 12px 24px;
    border: 1px solid #DCDEE3;
    background: #fff;
  }
  .group-wrap {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #DCDEE3;
    background: #fff;
  }
  .group-title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tit {
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
    }
  }
  .btn-expand {
    line-height: 24px;
    cursor: pointer;
    .text {
      font-style: normal;
      margin-right: 4px;
    }
  }
  .group-content {
    padding: 20px 0;
  }
  .module-wrap {
    border: 1px solid #DCDEE3;
    margin-bottom: 20px;
  }
  .module-title-box {
    padding: 0 24px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fafafa;
    .tit {
      font-size: 13px;
      margin-right: 8px;
    }
    .tag {
      font-size: 10px;
      color: #fff;
      padding: 2px 6px;
      border-radius: 2px;
      background: #999;
      &.act {
        background: #32D74B;
      }
    }
  }
  .module-content {
    border-top: 1px solid #DCDEE3;
    padding: 20px;
  }
  .prop-btn {
    padding: 8px 8px;
    margin: 0 4px;
    cursor: pointer;
    color: #666;
    &:hover {
      color: #000;
    }
  }
}

.drag-list-group {
  border-radius: 2px;
  min-height: 40px;
  .flip-list-move {
    transition: transform 0.4s;
  }
  .no-move {
    transition: transform 0s;
  }
  .list-group-item {
    padding: 0 16px;
    color: #000;
    height: 34px;
    line-height: 34px;
    font-size: 12px;
    cursor: move;
    background: #fff;
    border: 1px solid #ddd;
    margin-bottom: 8px;
    &.ghost {
      opacity: 0.5;
      // background: #89b5ee;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

</style>